document.addEventListener('DOMContentLoaded', function () {
    AOS.init();
    // Quando o usuário rolar 80px para baixo a partir do topo do documento, redimensione o cabeçalho de navegação
    window.onscroll = function() {scrollFunction()};

    var header = document.getElementById("header"); // Substitua "myHeader" pelo ID do seu cabeçalho
    var sticky = header.offsetTop;

    function scrollFunction() {
        if (window.pageYOffset > sticky) {
            header.classList.add("fixed-header");
        } else {
            header.classList.remove("fixed-header");
        }
    }
    // Seleciona todos os contêineres de carrossel
    const carouselContainers = document.querySelectorAll('.carousel-container');

    carouselContainers.forEach(container => {
        const slides = container.querySelectorAll('.carousel-img');
        const numberContainer = container.querySelector('.carousel-numbers');

        // Criando as setas
        const arrowLeft = document.createElement('button');
        arrowLeft.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24" fill="none">\n' +
            '                        <path fill-rule="evenodd" clip-rule="evenodd" d="M15.7071 4.29289C16.0976 4.68342 16.0976 5.31658 15.7071 5.70711L9.41421 12L15.7071 18.2929C16.0976 18.6834 16.0976 19.3166 15.7071 19.7071C15.3166 20.0976 14.6834 20.0976 14.2929 19.7071L7.29289 12.7071C7.10536 12.5196 7 12.2652 7 12C7 11.7348 7.10536 11.4804 7.29289 11.2929L14.2929 4.29289C14.6834 3.90237 15.3166 3.90237 15.7071 4.29289Z" fill="#fff"/>\n' +
            '                    </svg>';
        arrowLeft.classList.add('arrow-button', 'left', 'end-of-carousel','top-20',  'md:top-1/2', 'transform', 'md:-translate-y-1/2', 'absolute', 'left-8');
        container.appendChild(arrowLeft);

        const arrowRight = document.createElement('button');
        arrowRight.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="50px" height="50px" viewBox="0 0 24 24" fill="none">\n' +
            '                        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.29289 4.29289C8.68342 3.90237 9.31658 3.90237 9.70711 4.29289L16.7071 11.2929C17.0976 11.6834 17.0976 12.3166 16.7071 12.7071L9.70711 19.7071C9.31658 20.0976 8.68342 20.0976 8.29289 19.7071C7.90237 19.3166 7.90237 18.6834 8.29289 18.2929L14.5858 12L8.29289 5.70711C7.90237 5.31658 7.90237 4.68342 8.29289 4.29289Z" fill="#fff"/>\n' +
            '                    </svg>';
        arrowRight.classList.add('arrow-button', 'right', 'absolute', 'top-20', 'md:top-1/2', 'transform', 'md:-translate-y-1/2', 'right-8');
        container.appendChild(arrowRight);

        let currentIndex = 0;
        let timer;

        // Cria numeros para o carrossel
        slides.forEach((slide, index) => {
            let number = document.createElement('span');
            number.classList.add('carousel-number');
            number.textContent = index + 1;
            if (index === 0) number.classList.add('active');
            number.addEventListener('click', () => {
                moveToSlide(index -1, container);
                resetTimer();
            });
            numberContainer.appendChild(number);
        });

        const numbers = numberContainer.querySelectorAll('.carousel-number');

        // Modifique a função moveToSlide para lidar com indices extrapassando as bordas.
        function moveToSlide(index, container) {
            if (slides.length === 0) return;
            index = ((index % slides.length) + slides.length) % slides.length;

            if(index == 0){
                arrowLeft.classList.add("end-of-carousel")
            }else{
                arrowLeft.classList.remove("end-of-carousel")
            }

            if(index == slides.length - 1){
                arrowRight.classList.add("end-of-carousel")
            }else{
                arrowRight.classList.remove("end-of-carousel")
            }

            const width = container.querySelector('.carousel-img').clientWidth;
            container.querySelector('.carousel-slide').style.transform = `translateX(-${index * width}px)`;

            numbers.forEach(number => number.classList.remove('active'));
            numbers[index].classList.add('active');
            currentIndex = index;
        }

        function resetTimer() {
            clearInterval(timer);
            startTimer();
        }

        function startTimer() {
            const updateSlide = () => {
                currentIndex = (currentIndex + 1) % slides.length;
                let defaultInterval = 8000;
                let interval = defaultInterval;
                if (slides[currentIndex] && slides[currentIndex].hasAttribute('data-interval')) {
                    let slideInterval = slides[currentIndex].getAttribute('data-interval');
                    interval = slideInterval ? parseInt(slideInterval, 10) : defaultInterval;
                }

                moveToSlide(currentIndex, container);
                clearInterval(timer);
                timer = setInterval(updateSlide, interval);
            };

            updateSlide();
        }

        // Adicione manipuladores de clique aqui
        arrowRight.addEventListener('click', () => {
            clearInterval(timer);
            if (currentIndex < slides.length - 1) {
                console.log('click right')
                moveToSlide(currentIndex, container);
                console.log(currentIndex)
                startTimer();
            }
        });

        arrowLeft.addEventListener('click', () => {
            clearInterval(timer);
            if (currentIndex > 0) {
                currentIndex = currentIndex -2;
                moveToSlide(currentIndex, container);
                console.log(currentIndex)
                startTimer();
            }
        });

        startTimer();
    });

    // Configuração do acordeão
    document.querySelectorAll('.accordion-header').forEach(button => {
        button.addEventListener('click', () => {

            // Referência para a imagem
            const imagePremissa = document.getElementById('imagePremissa');
            const newImgSrc = button.dataset.image;

            // Passar por todos os headers do acordeão e fechar eles.
            document.querySelectorAll('.accordion-header').forEach(btn => {
                if (btn !== button) {
                    btn.classList.remove('active');
                    btn.nextElementSibling.classList.remove('show');
                }
            });

            // Alternar o estado do acordeão clicado.
            button.classList.toggle('active');
            button.nextElementSibling.classList.toggle('show');

            // Fazer a imagem atual desaparecer
            imagePremissa.style.opacity = '0';

            // Alterar a imagem após um pequeno atraso
            setTimeout(() => {
                imagePremissa.setAttribute('src', newImgSrc);
                imagePremissa.style.opacity = '1'; // Fazer a nova imagem aparecer
            }, 500); // Tempo de transição da opacidade
        });
    });

    // Configuração do lightbox
    const images = document.querySelectorAll('.lightbox-trigger');
    const lightbox = document.getElementById('lightbox');
    const lightboxImg = document.getElementById('lightbox-img');
    const lightboxClose = document.getElementById('lightbox-close');

    if (images) {
        images.forEach(img => {
            img.addEventListener('click', function () {
                lightboxImg.src = this.src;
                lightbox.classList.remove('hidden');
            });
        });
    }

    if (lightboxClose) {
        lightboxClose.addEventListener('click', function () {
            lightbox.classList.add('hidden');
        });
    }

    if (lightbox) {
        lightbox.addEventListener('click', function (e) {
            if (e.target !== lightboxImg) {
                lightbox.classList.add('hidden');
            }
        });
    }

    // Configuração do contato
    const contatoLink = document.querySelectorAll('a[href="#contato"]');
    const contato = document.getElementById('contato');
    const closeContato = document.getElementById('closeContato');

    // Configuração do Em Breve
    const embreveLink = document.querySelectorAll('a[href="#emBreve"]');
    const embreve = document.getElementById('emBreve');
    const closeEmbreve = document.getElementById('closeEmBreve');

    if (contatoLink && contato) {
        for(i = 0; i < contatoLink.length; i++ ) {
            contatoLink[i].addEventListener('click', function (event) {
                event.preventDefault();
                AOS.refresh();
                contato.classList.remove('no-show');
                document.body.classList.add('overlayer');
            });
        }
    }

    if (closeContato && contato) {
        closeContato.addEventListener('click', function () {
            AOS.refresh();
            contato.classList.add('no-show');
            document.body.classList.remove('overlayer');
        });
    }

    if (embreveLink && embreve) {
        for(i = 0; i < embreveLink.length; i++ ) {
            embreveLink[i].addEventListener('click', function (event) {
                event.preventDefault();
                AOS.refresh();
                embreve.classList.remove('no-show');
                document.body.classList.add('overlayer');
            });
        }
    }

    if (closeEmbreve && embreve) {
        closeEmbreve.addEventListener('click', function () {
            AOS.refresh();
            embreve.classList.add('no-show');
            document.body.classList.remove('overlayer');
        });
    }

    // Encontra o iframe (vídeo) e o botão de play (SVG)
    var iframe = document.querySelector('.video-container iframe');
    var playButton = document.querySelector('.video-container .video-play-button');

    if(iframe && playButton) {
        // Esconde inicialmente o iframe
        iframe.style.display = 'none';

        // Adiciona um ouvinte de evento de clique no botão de play
        playButton.addEventListener('click', function () {
            // Exibe o iframe e inicia o vídeo
            iframe.style.display = 'block';

            // Remove o botão de play
            this.style.display = 'none';

            // Inicia o vídeo automaticamente
            var src = iframe.getAttribute('src');
            if (src.indexOf('autoplay') === -1) {
                if (src.indexOf('?') === -1) {
                    src += '?';
                } else {
                    src += '&';
                }
                src += 'autoplay=1';
            }
            iframe.setAttribute('src', src);
        });
    }
    // Seleciona todos os links com a classe 'scroll'
    var scrollLinks = document.querySelectorAll('a.scroll');

    // Adiciona um ouvinte de evento de clique para cada link
    scrollLinks.forEach(function (link) {
        link.addEventListener('click', function (e) {
            // Previne a ação padrão do link
            e.preventDefault();

            // Pega o valor do atributo 'href' do link
            var targetId = this.getAttribute('href');

            // Pega o elemento correspondente ao ID
            var targetElement = document.querySelector(targetId);

            // Verifica se o elemento existe
            if (targetElement) {
                // Usa o método scrollTo para suavizar a rolagem até o elemento
                window.scrollTo({
                    'behavior': 'smooth',
                    'top': targetElement.offsetTop
                });
            }
        });
    });

    //inicio carrossel autores
    const items = document.querySelectorAll('.carousel .carousel-item');
    const dots = document.querySelectorAll('.carousel .dot');

    let currentIndex1 = 0;

    function showSlide(index) {
        if (dots && items) {
            items.forEach(item => {
                item.classList.remove('opacity-100')
                item.classList.add('absolute')
            });
            if (items[index]) {
                items[index].classList.add('opacity-100');
                items[index].classList.remove('absolute');
            }
            dots.forEach(dot => dot.classList.remove('active'));
            if (dots[index]) {
                dots[index].classList.add('active');
            }

            currentIndex1 = index;
        }
    }

    if (document.querySelector('.carousel .carousel-prev')) {
        document.querySelector('.carousel .carousel-prev').addEventListener('click', () => {
            const nextIndex = (currentIndex1 - 1 + items.length) % items.length;
            showSlide(nextIndex);
        });
    }

    if (document.querySelector('.carousel .carousel-next')) {
        document.querySelector('.carousel .carousel-next').addEventListener('click', () => {
            const nextIndex = (currentIndex1 + 1) % items.length;
            showSlide(nextIndex);
        });
    }

    dots.forEach((dot, index) => {
        dot.addEventListener('click', () => showSlide(index));
    });

    showSlide(0); // Mostra o primeiro slide

    let sliderItems = document.querySelectorAll('.carousel .carousel-item');

    if (sliderItems.length > 0) {
        let firstItemHeight = sliderItems[0].offsetHeight;
        if(window.width < 600 ) {
            document.querySelector('.carousel .carousel-inner').style.minHeight = firstItemHeight + 'px';
        }
    }

    //fim carrossel autores

    //inicio carrossel acabamento
    const items1 = document.querySelectorAll('.carousel1 .carousel-item');
    const dots1 = document.querySelectorAll('.carousel1 .dot');

    let currentIndex2 = 0;

    function showSlide1(index) {
        if (dots1 && items1) {
            items1.forEach(item => {
                item.classList.remove('opacity-100')
                item.classList.add('absolute')
            });
            if (items1[index]) {
                items1[index].classList.add('opacity-100');
                items1[index].classList.remove('absolute');
            }
            dots1.forEach(dot => dot.classList.remove('active'));
            if (dots1[index]) {
                dots1[index].classList.add('active');
            }

            currentIndex2 = index;
        }
    }

    if (document.querySelector('.carousel1 .carousel-prev')) {
        document.querySelector('.carousel1 .carousel-prev').addEventListener('click', () => {
            const nextIndex = (currentIndex2 - 1 + items1.length) % items1.length;
            showSlide1(nextIndex);
        });
    }

    if (document.querySelector('.carousel1 .carousel-next')) {
        document.querySelector('.carousel1 .carousel-next').addEventListener('click', () => {
            const nextIndex = (currentIndex2 + 1) % items1.length;
            showSlide1(nextIndex);
        });
    }

    dots1.forEach((dot1, index1) => {
        dot1.addEventListener('click', () => showSlide1(index1));
    });

    showSlide1(0); // Mostra o primeiro slide

    let sliderItems1 = document.querySelectorAll('.carousel1 .carousel-item');

    if (sliderItems1.length > 0) {
        let firstItemHeight = sliderItems[0].offsetHeight;
        if(window.width < 600 ) {
            document.querySelector('.carousel1 .carousel-inner').style.minHeight = firstItemHeight + 'px';
        }
    }

    //fim carrossel acabamento

    const scrollContainer = document.querySelector(".scroll-container");

    if (scrollContainer) { // Checar se o container existe.
        const boxWidth = document.querySelector('.box').offsetWidth;
        const scrollLeft = document.querySelector(".scroll-left");
        const scrollRight = document.querySelector(".scroll-right");

        scrollLeft.addEventListener("click", function() {
            scrollContainer.scrollBy({top: 0, left: -boxWidth, behavior: 'smooth'});
        });

        scrollRight.addEventListener("click", function() {
            scrollContainer.scrollBy({top: 0, left: boxWidth, behavior: 'smooth'});
        });

        scrollContainer.addEventListener("scroll", function() {
            if (scrollContainer.scrollLeft + scrollContainer.offsetWidth >= scrollContainer.scrollWidth) {
                scrollRight.classList.add("scroll-limit-reached");
            } else {
                scrollRight.classList.remove("scroll-limit-reached");
            }

            if (scrollContainer.scrollLeft === 0) {
                scrollLeft.classList.add("scroll-limit-reached");
            } else {
                scrollLeft.classList.remove("scroll-limit-reached");
            }
        });
    }

    document.querySelector('.openMenu').addEventListener('click', function() {
        // Adiciona a classe 'overlayer' ao elemento body
        // Realiza o scroll de 200px
        window.scrollBy(0, 100);

        // Depois de realizar o scroll, remove a classe 'hidden' do elemento 'header nav'
        document.querySelector('header nav').classList.remove('hidden');
        document.body.classList.add('overlayerHidden');
    });

    document.querySelector('#closeMenu').addEventListener('click', function() {
        // Remove a classe 'overlayer' do elemento body
        document.body.classList.remove('overlayerHidden');

        // Realiza o scroll de 200px para cima
        window.scrollBy(0, -100);

        // Adiciona a classe 'hidden' ao elemento 'header nav'
        document.querySelector('header nav').classList.add('hidden');
    });

    // Selecione o elemento com o seletor específico
    var select = document.querySelector('select.wpcf7-form-control.wpcf7-select.wpcf7-validates-as-required');
    if (!select) return; // Se o select não existir na página, pare aqui.

    var selectWrapper = document.createElement('div');
    var currentSelection = document.createElement('div');
    var optionsContainer = document.createElement('div');

    selectWrapper.className = 'select-wrapper';
    currentSelection.className = 'current-selection';
    optionsContainer.className = 'options-container';

    selectWrapper.appendChild(currentSelection);
    selectWrapper.appendChild(optionsContainer);

    select.style.display = 'none';
    select.parentNode.insertBefore(selectWrapper, select);

    currentSelection.textContent = select.options[select.selectedIndex].textContent;

    for (var i = 0; i < select.options.length; i++) {
        var optionElement = document.createElement('div');
        optionElement.className = 'option';
        optionElement.textContent = select.options[i].textContent;
        optionElement.dataset.value = select.options[i].value;

        optionElement.addEventListener('click', function() {
            select.value = this.dataset.value;
            currentSelection.textContent = this.textContent;
            optionsContainer.style.display = 'none';
        });

        optionsContainer.appendChild(optionElement);
    }

    currentSelection.addEventListener('click', function() {
        optionsContainer.style.display = optionsContainer.style.display === 'none' ? 'block' : 'none';
    });
});
